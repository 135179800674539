import React from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';



const YoutubeSlide = ({ url, isSelected }: { url: string; isSelected?: boolean }) => (
    <ReactPlayer width="100%" url={url}  />
);

const Videos = () => {
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

    const customRenderThumb = (children) =>
        children.map((item) => {
            const videoId = getVideoId(item.props.url);
            return <img src={getVideoThumb(videoId)} />;
        });

    return (
        <Carousel autoplay={false} showStatus={false} showIndicators={false} renderItem={customRenderItem} renderThumbs={customRenderThumb}>
            <YoutubeSlide key="youtube-4" url="https://www.youtube.com/embed/cG_ynwWqr8E" />
            <YoutubeSlide key="youtube-2" url="https://www.youtube.com/embed/WbnJl6xXt4U" />
            <YoutubeSlide key="youtube-1" url="https://www.youtube.com/embed/QHbzI3sLC14" />
            <YoutubeSlide key="youtube-3" url="https://www.youtube.com/embed/1jSH_H2Ll8s" />
        </Carousel>
    );
};

export default Videos;