import Header from './components/Header';
import Quote from './components/Pages/Quote';

import Videos from './components/Videos';
import About from './components/About';
import Gallery from './components/Gallery';
import Landing from './components/Pages/Landing';
import Resume from './components/Pages/Resume';
import Media from './components/Pages/Media';
import Contact from './components/Pages/Contact';
import { Routes, Route, BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      
      <Header />
      <Landing />
      <Gallery />
      <Videos />

      <Quote>
            <div>
              “An exemplary actor combined with a well-developed work ethic. Lane has a unique ability to bring nuance and detail to each moment on stage, creating a multi-layer experience for both cast and audience.”
            </div>
            <div class="pt-8">
              -Jeff Whiting, President at Open Jar Studios and Director of The Lost Colony
            </div>
      </Quote>

      <About />

      <Quote>
            <div>
              “A joy to direct, Lane elevated the rehearsal room. He is a smart, creative actor who is always prepared. Cast him.”
            </div>
            <div class="pt-8">
              -Jamie Warrow, Director of A Bright Room Called Day and Trouble in Mind
            </div>
      </Quote>

      <Resume />

      <Routes>
       
        <Route path='/editLane' element={<Resume />}/>
                        
      </Routes>

      <Quote>
        <div>
          “Lane is an incredibly inquisitive performer, consistent in the execution of his role but motivated always by the potential for improvement. The type of collaboration one dream of finding in every young actor.”        
        </div>
        <div class="pt-8">
          -Jason Paul Tate, SAFD certified instructor and Fight Choreographer of The Lost Colony
        </div>
      </Quote>
      <Contact /> 

    </BrowserRouter>

  );
}

export default App;
